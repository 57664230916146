<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="products"
            :search="search"
            class="elevation-1"
            style="background: #f5f5f5"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            :loading="loading"
            loading-text="Please wait..."
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <b>Product Id: </b> {{ item.id }} <br />
                <b>Category : </b> {{ item.category }}<br />
                <b>Sub category: </b> {{ item.sub_category }}<br />
                <b>Sub Sub category : </b> {{ item.sub_sub_category }}<br />
                <b>Product Title: </b> {{ item.title }}<br />
                <b>Product Brand: </b> {{ item.brand }}<br />
                <b>Seller: </b> {{ item.seller_name }}<br />
                <!-- <b>Publicly Shareable: </b> {{ item.properties -->
                <!-- }}<br /> -->
                <b>Origin: </b> {{ item.origin }}<br />
                <b>Shipping Country: </b> {{ item.shipping_country }}<br />
              </td>
            </template>
            <template v-slot:top>
              <v-toolbar elevation="2" style="background: #f5f5f5" class="mt-2">
                <v-toolbar-title
                  style="margin-left: -14px"
                  class="mt-2 mb-2 ml-2"
                >
                  <div>All Product</div>
                </v-toolbar-title>

                <v-spacer></v-spacer>
                <div>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    filled
                    rounded
                    dense
                    hide-details
                  ></v-text-field>
                </div>

                <v-divider class="mx-4" inset vertical></v-divider>
              </v-toolbar>
            </template>

            <v-spacer> </v-spacer>
            <template v-slot:item.specification="{ item }">
              <v-btn shaped text @click="viewSpecification(item)" color="green">
                <v-icon dark> mdi-plus</v-icon>
                Specification
              </v-btn>
            </template>
            <template v-slot:item.review="{ item }">
              <v-btn
                shaped
                text
                @click="viewReview(item)"
                color="blue lighten-2"
              >
                <v-icon dark class="pr-2"> mdi-eye-outline</v-icon>
                Review
              </v-btn>
            </template>
            <template v-slot:item.comments="{ item }">
              <v-btn
                shaped
                text
                @click="viewComments(item)"
                color="blue lighten-2"
              >
                <v-icon dark class="pr-2"> mdi-eye-outline</v-icon>
                Questions
              </v-btn>
            </template>

            <template v-slot:item.link="{ item }">
              <v-btn shaped text @click="viewLink(item)" color="#FF7743">
                <v-icon class="mr-2" dark> mdi-link</v-icon>
                Link
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
import { VueCropper } from "vue-cropper";

export default {
  data: () => ({
    crap: false,

    snackbar: false,
    text: "",
    color: "",

    loading: true,

    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Title", value: "title" },
      { text: "Brand", value: "brand", sortable: false },
      { text: "No.Spec", value: "specific_status", sortable: false },
      { text: "", value: "specification", sortable: false },
      { text: "", value: "status", sortable: false },
      // { text: "Selling Price", value: "old_price" },
      { text: "", value: "review", sortable: false },
      { text: "", value: "comments", sortable: false },
      { text: "", value: "link", sortable: false },

      { text: "", value: "data-table-expand" },
    ],
    products: [],
    contact: {},
    editedIndex: -1,
    allBrands: [],

    user: {},

    expanded: [],
    singleExpand: false,
  }),

  components: {
    VueCropper,
  },



  methods: {
    initialize() {
        axios.get("product/imported_product_details/").then((response) => {
          this.products = response.data.data;
          this.loading = false;
        });
  
    },

    viewSpecification(item) {
      let routeData = this.$router.resolve({
        name: `/supportdashboard/pages/import_specification/${item.id}`,
      });
      routeData.href = `/supportdashboard/pages/import_specification/?id=${item.id}&unit=${item.unit}`;
      window.open(routeData.href, "_blank");
    },

    viewReview(item) {
      let routeData = this.$router.resolve({
        name: `/supportdashboard/pages/review/${item.id}`,
      });
      routeData.href = `/supportdashboard/pages/review/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },

    viewComments(item) {
      let routeData = this.$router.resolve({
        name: `/supportdashboard/pages/viewcomments/${item.id}`,
      });
      routeData.href = `/supportdashboard/pages/viewcomments/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },

    viewLink(item) {
      let routeData = this.$router.resolve({
        name: `https://mymarket.com.bd/products/${item.title.replaceAll(
          " ",
          "-"
        )}/details/?spm=${item.id}`,
      });
      routeData.href = `https://mymarket.com.bd/products/${item.title.replaceAll(
        " ",
        "-"
      )}/details/?spm=${item.id}`;
      window.open(routeData.href, "_blank");
    },


 



    // This Link will be Change for link change
    print(select) {
      if (this.select == "All Products") {
        let routeData = this.$router.resolve({
          //name: `http://68.183.231.43/site/get_pdf/`,
          name: `site/get_pdf/`,
        });
        //routeData.href = `http://68.183.231.43/site/get_pdf/`;
        routeData.href = `site/get_pdf/`;
        window.open(routeData.href, "_blank");
      } else if (this.select == "No Specification") {
        let routeData = this.$router.resolve({
          name: `get_nospec_pdf/`,
          //name: `http://68.183.231.43/site/get_nospec_pdf/`,
        });
        //routeData.href = `http://68.183.231.43/site/get_nospec_pdf/`;
        routeData.href = `site/get_nospec_pdf/`;
        window.open(routeData.href, "_blank");
      } else if (this.select == "No Price") {
        let routeData = this.$router.resolve({
          // name: `http://68.183.231.43/site/get_nospecPrice_pdf/`,
          name: `site/get_nospecPrice_pdf/`,
        });
        //routeData.href = `http://68.183.231.43/site/get_nospecPrice_pdf/`;
        routeData.href = `site/get_nospecPrice_pdf/`;
        window.open(routeData.href, "_blank");
      } else {
        let routeData = this.$router.resolve({
          // name: `http://68.183.231.43/site/get_pdf/`,
          name: `site/get_pdf/`,
        });
        //routeData.href = `http://68.183.231.43/site/get_pdf/`;
        routeData.href = `site/get_pdf/`;
        window.open(routeData.href, "_blank");
      }

      // console.log("this is for method test" ,this.select)
      // let routeData = this.$router.resolve({
      //   name: `https://tes.com.bd/site/get_pdf/`,
      // });
      // routeData.href = `https://tes.com.bd/site/get_pdf/`;
      // window.open(routeData.href, "_blank");
    },


    productselect() {
      if (this.select == "All Products") {
        axios.get("product/product_details/").then((response) => {
          this.products = response.data;
          this.loading = false;
        });
      } else if (this.select == "No Specification") {
        axios.get("product/nospecification_products/").then((response) => {
          this.products = response.data.data;
          this.loading = false;
        });
      } else {
        axios.get("product/noprice_products/").then((response) => {
          this.products = response.data.data;
          this.loading = false;
        });
      }
    },
  },
  mounted() {
    this.initialize();
    this.getAllCastegory();
    this.getAllBrands();
    this.productselect();
    this.user = JSON.parse(localStorage.getItem("user"));
  },
};
</script>


<style>
.cut {
  width: 500px;
  height: 560px;
  margin: 30px auto;
  margin-top: 0px;
}

.c-item {
  max-width: 800px;
  margin: 10px auto;
  margin-top: 20px;
}

.content {
  margin: auto;
  max-width: 1200px;
  margin-bottom: 100px;
}

.cropper {
  height: 600px;
  background: #ddd;
}

.cropper-view-box {
  outline: 1px solid red !important;
}

.thumbnailImage:hover {
  cursor: pointer;
}

.v-sheet--offset {
  top: -10px;
  position: relative;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

