var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-10",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ml-7",staticStyle:{"width":"96%"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"background":"#f5f5f5"},attrs:{"headers":_vm.headers,"items":_vm.products,"search":_vm.search,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":"","loading":_vm.loading,"loading-text":"Please wait..."},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('b',[_vm._v("Product Id: ")]),_vm._v(" "+_vm._s(item.id)+" "),_c('br'),_c('b',[_vm._v("Category : ")]),_vm._v(" "+_vm._s(item.category)),_c('br'),_c('b',[_vm._v("Sub category: ")]),_vm._v(" "+_vm._s(item.sub_category)),_c('br'),_c('b',[_vm._v("Sub Sub category : ")]),_vm._v(" "+_vm._s(item.sub_sub_category)),_c('br'),_c('b',[_vm._v("Product Title: ")]),_vm._v(" "+_vm._s(item.title)),_c('br'),_c('b',[_vm._v("Product Brand: ")]),_vm._v(" "+_vm._s(item.brand)),_c('br'),_c('b',[_vm._v("Seller: ")]),_vm._v(" "+_vm._s(item.seller_name)),_c('br'),_c('b',[_vm._v("Origin: ")]),_vm._v(" "+_vm._s(item.origin)),_c('br'),_c('b',[_vm._v("Shipping Country: ")]),_vm._v(" "+_vm._s(item.shipping_country)),_c('br')])]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mt-2",staticStyle:{"background":"#f5f5f5"},attrs:{"elevation":"2"}},[_c('v-toolbar-title',{staticClass:"mt-2 mb-2 ml-2",staticStyle:{"margin-left":"-14px"}},[_c('div',[_vm._v("All Product")])]),_c('v-spacer'),_c('div',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.specification",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"shaped":"","text":"","color":"green"},on:{"click":function($event){return _vm.viewSpecification(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus")]),_vm._v(" Specification ")],1)]}},{key:"item.review",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"shaped":"","text":"","color":"blue lighten-2"},on:{"click":function($event){return _vm.viewReview(item)}}},[_c('v-icon',{staticClass:"pr-2",attrs:{"dark":""}},[_vm._v(" mdi-eye-outline")]),_vm._v(" Review ")],1)]}},{key:"item.comments",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"shaped":"","text":"","color":"blue lighten-2"},on:{"click":function($event){return _vm.viewComments(item)}}},[_c('v-icon',{staticClass:"pr-2",attrs:{"dark":""}},[_vm._v(" mdi-eye-outline")]),_vm._v(" Questions ")],1)]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"shaped":"","text":"","color":"#FF7743"},on:{"click":function($event){return _vm.viewLink(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v(" mdi-link")]),_vm._v(" Link ")],1)]}}])},[_c('v-spacer')],1)],1)],1)],1),_c('v-snackbar',{attrs:{"centered":"","color":_vm.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }